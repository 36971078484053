import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import navigation from './navigation';
import ui from './ui';
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    navigation,
    ui,
  },
});
