import Vue from 'vue';
import VueRouter from 'vue-router';
import Home2 from '../views/Home2.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home2',
    component: Home2,
  },
  {
    path: '/about-us',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/services/:code',
    name: 'Services',
    params: true,
    component: () => import('../views/Services.vue'),
  },
  {
    path: '/recruitment',
    name: 'Recruitment',
    component: () => import('../views/Recruitment.vue'),
  },
  {
    path: '/industry',
    name: 'Industry',
    component: () => import('../views/Industry.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
