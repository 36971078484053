const state = {
  navlinks: [
    {
      title: 'Home',
      url: '',
    },
    {
      title: 'About Us',
      url: 'about-us',
    },
    {
      title: 'Services',
      url: 'services',
    },
    {
      title: 'Industry',
      url: 'industry',
    },
    {
      title: 'Recruitment',
      url: 'recruitment',
    },
    {
      title: 'Contact Us',
      url: 'contact',
    },
  ],
  drawer: false,
};

const actions = {
  flipDrawer({ commit }) {
    commit('SET_DRAWER', !state.drawer);
  },
};

const mutations = {
  SET_DRAWER(state, value) {
    state.drawer = value;
  },
};

const getters = {
  getNavlinks(state) {
    return state.navlinks;
  },
  showDrawer(state) {
    return state.drawer;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
