<template>
  <v-navigation-drawer
    app
    bottom
    temporary
    v-model="showNavigation"
    v-click-outside="closeModal"
    v-if="showDrawer"
    class="nav-drawer text-center"
  >
    <div id="navbar-body">
      <span @click="redirect('')">Home</span>
      <span @click="redirect('about-us')">About Us</span>
      <p class="mb-0">Value Propositions</p>
      <span
        class="nested-list"
        v-for="(item, index) in services"
        :key="index"
        @click="redirect(`services/${item.code}`)"
      >
        {{ item.title }}
      </span>
      <span @click="redirect('industry')">Industry</span>
      <span @click="redirect('recruitment')">Recruitment</span>
      <span @click="redirect('contact')">Contact Us</span>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      showNavigation: false,
      services: [
        { title: 'Staffing Solutions', code: 1 },
        { title: 'Executive Search', code: 2 },
        { title: 'Payroll Services', code: 3 },
        { title: 'Recruitment Process Outsourcing', code: 4 },
        { title: 'Training & Development', code: 5 },
      ],
    };
  },
  computed: {
    ...mapGetters(['getNavlinks', 'showDrawer']),
  },

  methods: {
    redirect(url) {
      if (`/${url}` !== this.$route.path) {
        this.$router.push(`/${url}`);
      }
    },
    closeModal() {
      if (!this.showNavigation) {
        this.$store.dispatch('flipDrawer');
      }
    },
  },

  watch: {
    showDrawer() {
      this.showNavigation = this.showDrawer;
    },
  },
};
</script>

<style scoped>
.nav-drawer {
  max-height: 300px;
}
#navbar-body {
  display: flex;
  padding: 5px 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 10px 20px;
}
#navbar-body span {
  padding: 5px 0;
}
.service-dropdown {
  background: transparent !important;
  box-shadow: none;
  border: none;
  color: #fff !important;
}
.nested-list {
  margin-left: 15px;
  padding: 2px 0 !important;
}
.nested-list::before {
  content: '- ';
}
</style>
