<template>
  <div id="navbar-body">
    <span @click="redirect('')">Home</span>
    <span @click="redirect('about-us')">About Us</span>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="service-dropdown">
          Value Propositions
          <v-icon large class="ml-1">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in services" :key="index">
          <v-list-item-title
            class="service-list-items"
            @click="redirect(`services/${item.code}`)"
            >{{ item.title }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
    <span @click="redirect('industry')">Industry</span>
    <span @click="redirect('recruitment')">Recruitment</span>
    <span @click="redirect('contact')">Contact Us</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      services: [
        { title: 'Staffing Solutions', code: 1 },
        { title: 'Executive Search', code: 2 },
        { title: 'Payroll Services', code: 3 },
        { title: 'Recruitment Process Outsourcing', code: 4 },
        { title: 'Training & Development', code: 5 },
      ],
    };
  },
  methods: {
    redirect(url) {
      if (`/${url}` !== this.$route.path) {
        this.$router.push(`/${url}`);
      }
    },
  },
};
</script>

<style scoped>
#navbar-body {
  display: flex;
  padding: 5px 0;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.service-dropdown {
  background: transparent !important;
  box-shadow: none;
  border: none;
  color: #fff !important;
}
.service-list-items {
  padding: 5px;
  cursor: pointer;
  margin: 0 !important;
}
.service-list-items:hover {
  background: #eee;
}
</style>
