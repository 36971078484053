<template>
  <div id="footer">
    <div class="grid">
      <p>ATES 2022</p>
      <p>All Rights Reserved</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#footer {
  padding: 10px 20px;
  background: var(--theme);
  color: white;
}
p {
  margin-bottom: 0;
}
.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
