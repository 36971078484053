const para1 = {
  text: `ATES was established as an organization to meet the needs of both
        talented individuals and clients searching for the perfect fit. We
        streamline the recruiting process for our clients while taking the
        necessary time to get to know our candidates. Having this connection
        with both our candidates and our clients has enabled us to grow in
        today’s marketplace where many of our competitors use a “one and done”
        approach. We help our candidates grow their careers to a level where
        they often become our clients! While the landscape of the recruiting
        world has changed due to growth of social media, ATES has not lost sight
        of what has made us a success – our personal relationships! We have a
        world-class, state of the art database which keeps us in frequent
        contact with both our candidates and our clients and enables us to
        maintain impeccable records and deliver results in the most expeditious
        manner. At ATES we believe in specialization. We group our recruiters in
        their specific areas of expertise, so you can be sure your recruiter
        knows just what you are looking for whether it be for an entry level,
        mid level or executive level position.`,
  alignment: 'text-justify',
};

const para2 = {
  text: ` Since our inception, ATES has helped
  several Startups, Pvt. Ltd, Public Ltd Companies and Global Organization
  build great teams. So, if you are looking for recruitment experts to
  assist you in hiring right people for even the most challenging general
  or leadership positions, ATES Consulting is the right place. At ATES, we
  adapt industry best practices and latest technologies to provide you
  unique and strategic recruitment services, you don't just fill job
  positions with ATES, but our priority is to make sure that you get the
  best match. Being a specialist recruitment service firm, ATES firmly
  believes in “quality” not “quantity”.`,
  alignment: `text-justify`,
};

export { para1, para2 };
