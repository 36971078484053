<template>
  <div id="home-body">
    <Carousel />
    <div class="page-view">
      <h1 class="header-title text-center">Overview</h1>
      <Article :article="para1" />

      <h3 class="sub-header my-7">
        WHY US : HIGHEST SUCCESS RATE IN FILLING CHALLENGING JOB POSITIONS
      </h3>

      <img src="/home.jpg" alt="Overview" />
      <h4 class="sub-header my-5">Specialist Recruitment Services</h4>
      <Article :article="para2" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Article from '@/components/UI/Article.vue';
import { para1, para2 } from '@/assets/scripts/home.js';
import Carousel from '@/components/UI/Carousel.vue';
import Footer from '@/components/UI/Footer.vue';
export default {
  components: {
    Article,
    Carousel,
    Footer,
  },
  data: () => {
    return {
      para1,
      para2,
    };
  },
};
</script>

<style scoped>
img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}
.header-small {
  font-size: 20px;
  margin: 10px 0;
}
.sub-header {
  font-weight: bold;
}
</style>
