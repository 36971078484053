<template>
  <v-app id="backdrop-cover">
    <v-app-bar
      color="white"
      class="app-bar"
      prominent
      height="70px"
      max-height="70px"
      fixed
    >
      <div class="app-bar-content">
        <section class="toggler d-flex justify-space-between align-center">
          <img src="/logo.svg" alt="logo" class="logo-img" />
          <span @click="flipDrawer" class="navbar-toggle"
            ><v-icon size="35px" class="mr-5">mdi-menu</v-icon></span
          >
        </section>
      </div>
    </v-app-bar>

    <DropNav />
    <section class="navbar-items">
      <Navbar />
    </section>
    <v-main class="router-view">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import DropNav from '@/components/UI/DropNav.vue';
import Navbar from '@/components/Blocks/Navbar.vue';
export default {
  name: 'App',
  components: { DropNav, Navbar },
  methods: {
    flipDrawer() {
      this.$store.dispatch('flipDrawer');
    },
  },
  computed: {
    ...mapGetters(['get_Theme']),
  },
};
</script>

<style>
:root {
  --theme: #802665;
}
@import './assets/css/global.css';
.app-bar-content {
  width: 1200px;
  max-width: 1200px;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-items {
  margin-top: 75px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: var(--theme);
  color: #fff;
}

.navbar-items span {
  padding: 10px 15px;
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
}
.navbar-items span:hover {
  opacity: 0.8;
}

.navbar-toggle {
  display: none;
}
.logo-img {
  max-width: 100px;
}
.logo-box {
  padding: 0 10px;
  background-color: white;
  border-radius: 10px;
}
@media (max-width: 1100px) {
  .navbar-items span {
    padding: 10px;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .navbar-items {
    display: none;
  }
  .navbar-toggle {
    display: block;
  }
  .toggler {
    width: 100%;
  }
  .router-view {
    margin-top: 80px;
  }
}
</style>
