<template>
  <article :class="[`${article.alignment}`]">
    {{ article.text }}
  </article>
</template>

<script>
export default {
  props: ['article'],
};
</script>

<style scoped>
article {
  line-height: 1.6rem;
  margin-bottom: 20px;
  font-size: 16px;
}

@media (max-width: 768px) {
  article {
    line-height: 1.6rem;
    font-size: 14px;
  }
}
</style>
