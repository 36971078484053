const state = {
  theme: '#6c1f55',
};

const actions = {
  setTheme({ commit }, color) {
    commit('SET_THEME', color);
  },
};

const mutations = {
  SET_THEME(state, payload) {
    state.theme = payload;
  },
};

const getters = {
  get_Theme(state) {
    return state.theme;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
