<template>
  <v-carousel height="400">
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :src="item.src"
      reverse-transition="fade-transition"
      transition="fade-transition"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: 'https://source.unsplash.com/QckxruozjRg',
        },
        {
          src: 'https://source.unsplash.com/hCb3lIB8L8E',
        },
        {
          src: '/img3.png',
        },
        {
          src: 'https://source.unsplash.com/Of_m3hMsoAA',
        },
      ],
    };
  },
};
</script>
